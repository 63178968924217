import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from '../components/seo';
import Card from '../components/card/card';
import Visual from '../components/Visual'
import './report.css';
import ContentfulRichText from '../components/contentful/ContentfulRichText'


export default ({ data }) => {
  const hrefRegex = new RegExp('<a href', 'g');

  const [responses, setResponses] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // TODO: Make this work for POST as well? (will we ever need that?)
    const make_request = async (request) => {
      setLoading(true)
      let url = request.url
      const params = {method:request.method}
      if(request.headers) {
        params.headers = JSON.parse(request.headers.internal.content)
      }
      
      if(request.payload) {
        let queryString = new URLSearchParams();
        let url_params = JSON.parse(request.payload.internal.content);
        for(let key in url_params) {
          queryString.append(key, url_params[key])
        }
        url = url + `?${queryString.toString()}`
      }


      let req = await fetch(url, params);
      let result = await req.json();
      let new_responses = responses
      new_responses[request.title] = result
      setResponses(new_responses)
      setLoading(false)
    }
    if(data.contentfulReport.card) {
      data.contentfulReport.card.forEach((x) => {
        if(x.request) {
          if(!responses[x.request.title]) {
            make_request(x.request)
          }
        }
      });
    }
  })

  const live_text_style=`
    font-weight: 600;
    font-size: 1.1rem;
    padding: 1px 0 1px 3px;
    margin: 0 3px 0 0;
    background-color: rgba(255,255,255,0.3);
  `

  // how would we handle {{responses[0].fields.xyz}}

  const add_live_data = (card, html) => {
    if(card.request) {
      const data = responses[card.request.title]
      if(card.request) {
        html = html.replace(/{{.*?}}/g,
            (matched) => {
              const base = matched.replace(/{{|}}/g, '')
              const elements = base.split(/\.|\[|\]/).filter((el) => el ? true : false)
              let working_data
              if(data) {
                working_data = data
                for(let index in elements) {
                  var element = elements[index]
                  if(element.match(/\d*/)[0]!== '') {
                    element = parseInt(element)
                  }
                  working_data = working_data[element]
                }
                if(typeof working_data === 'number') {
                  working_data = Math.round(working_data*10)/10
                }
              }
              return (`
              <span style="${live_text_style}">
              ${working_data ? working_data : '...'}
              </span>
            `)
            }
          )
      }
    }
    return html
  }

  return (
    <Layout>
    <SEO
      title={data.contentfulReport.title}
      keywords={[`Tampa Bay`, `Startups`, `Data`]}
      image={data.contentfulReport.socialImage ? `https:${data.contentfulReport.socialImage.file.url}`: ''}
      url={`https://reports.embarccollective.com/${data.contentfulReport.slug}`}
      card='summary_large_image'
      description={data.contentfulReport.socialDescription}
    />
    <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexWrap:'nowrap', flexDirection:'column', padding:'2rem', maxWidth:`98vw`}}>
      <div className="intro-container">
        <h1>{data.contentfulReport.title}</h1>
        <ContentfulRichText richTextJSON={data.contentfulReport.introLede.json}/>
        { data.contentfulReport.introBody &&
          <ContentfulRichText richTextJSON={data.contentfulReport.introBody.json}/>
        }
      </div>
      <div className="card-container" style={{height:'100%', width:'100%', position:'relative'}}>
        { data.contentfulReport.card ? data.contentfulReport.card.sort((a,b)=> a.order > b.order ? 1 : -1).map((card, index) =>
          <Card
            key={index}
            backgroundColor={card.color}
            reverse={index%2 === 0 ? true : false}
            cardStyle={card.style === card.style && card.style !== null ? card.style : data.contentfulReport.style}
            flip={card.flip}
            flipTitle={card.flipTitle}
            flipJSON={card.flipContent ? card.flipContent.json : null}
            styleOverride={card.styleOverride}
          >
            <div style={{margin:`0 auto`}}>
              <div style={{padding:`15px`}}>
                {!card.hideOrder && <h1>{card.order}</h1>}
                <h2>{card.title}</h2>
                <ContentfulRichText richTextJSON={card.text.json}/>            
              </div>
            </div>
            {
              !(card.interactive === null && card.image === null) ? <Visual imageTitle={card.imageTitle} interactive={card.interactive} image={card.image} index={index}/> : null
            }
          </Card>
        ) : null}
      </div>
    </div>
  </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulReport(slug: {eq:$slug}) {
      title,
      slug,
      seoDescription,
      style,
      socialDescription,
      socialImage {
        file {
          url
        }
      }
      introLede {
        json
      },
      introBody {
        json
      }
      card {
        title
        imageTitle
        order
        styleOverride {
          internal {
            content
          }
        }
        hideOrder
        color
        style
        text {
          json
        }
        flip
        flipTitle
        flipContent {
          json
        },
        image {
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid
          }
        }
        interactive {
          type
          data {
            file {
              url
            }
          }
        }
        request {
          title
          method
          url
          headers {
            internal {
              content
            }
          }
          payload {
            internal {
              content
            }
          }
        }
      }
  }
}
`